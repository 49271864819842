import React, { useEffect, useState } from "react";
import Layout from "../components/Layouts/Layout";
import "../Style/howitwork.css";
import "../Style/custom.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { AiFillPlayCircle } from "react-icons/ai";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import HowitWork from "../../src/assets/howitworks.png";
import { BsGeoAltFill } from "react-icons/bs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import orgLogo from "../assets/listelligent-logo-orange.png";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

// Popup Video
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <video
          src={require("../../src/assets/video.mp4")}
          width="100%"
          height="auto"
          controls
          autoPlay
        ></video>
      </Modal.Body>
    </Modal>
  );
}

const steps = [
  {
    label: "Insert your address and contact info",
    description: `That’s it. No lengthy questions, spam calls or time wasting. See who serves your
    area immediately.`,
  },
  {
    label: "Immediate Access to Agent Profile",
    description:
      "Once you’ve inserted your address you’ll be taken directly to the profiles of local agents. No further questionnaires or requirements.",
  },
  {
    label: "Connect",
    description: `View the agent(s) profile of your requested zip code. After reviewing, hit the Request Interview for a call or email from that agent.`,
  },
  {
    label: "List and Save!",
    description: `Congrtatulations on selling with your new agent! `,
  },
];

const Howitwork = () => {
  // Popup Video
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowAddress, setModalShowAddress] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [address, setAddress] = useState("");
  const [faqsList, setFaqsList] = useState([]);

  const storedFormDataString = localStorage.getItem("formData");
  const storedFormData = storedFormDataString
    ? JSON.parse(storedFormDataString)
    : null;
  const [cookies, setCookie] = useState(storedFormData);

  const [formData, setFormData] = useState({
    name: cookies ? cookies["name"] : "",
    phone: cookies ? cookies["phone"] : "",
    email: cookies ? cookies["email"] : "",
    address: cookies ? cookies["address"] : "",
    zip_code: cookies ? cookies["zip_code"] : "",
    agent_zip_code: cookies ? cookies["agent_zip_code"] : "",
    state: cookies ? cookies["state"] : "",
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  useEffect(() => {
    getFaqs();
  }, []);
  const getFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/faqs`,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        setFaqsList(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const zipCodeComponent = results[0].address_components.find((component) =>
        component.types.includes("postal_code")
      );

      const newZipCode = zipCodeComponent ? zipCodeComponent.short_name : "";
      const stateComponent = results[0].address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const stateCode = stateComponent ? stateComponent.short_name : "";
      formData.state = stateCode;
      setAddress(selectedAddress);
      formData.address = selectedAddress;
      formData.agent_zip_code = newZipCode;
    } catch (error) {
      console.error("Error fetching location information:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmitzPro = (e) => {
    e.preventDefault();
    addUserLead();
  };

  const addUserLead = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/addLead`,
        formData,
        {
          headers: {
            Authorization: `Bearer 455454545ddsdksdjksdjksdsjdksjdksj`,
          },
          withCredentials: true,
        }
      );
      if (response.status) {
        localStorage.setItem("formData", JSON.stringify(formData));
        navigate("/agentslist");
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  return (
    <Layout>
      <div className="hiw-first-section">
        <Container>
          <NotificationContainer />
          <Row className="d-flex align-items-center">
            <Col md={6}>
              <h3>How Listelligent Works</h3>
              <p>
                Save time and money by working with local experts that will list
                your home for 1%
              </p>
              {/* <Form className="header-search"> */}
              {/* <Form.Control size="lg" type="text" placeholder="Search" /> */}
              {/* <Link to={"/agentslist"}>
                  GET STARTED
                  <HiOutlineArrowSmRight />
                </Link> */}
              <div className="text-center text-lg-start">
                <button
                  className="btn btn-light custom-button-css custom-button-white-bg"
                  onClick={() => setModalShowAddress(true)}
                >
                  Get Started
                </button>
              </div>
              {/* </Form> */}
            </Col>
            <Col md={6} className="video-popup-container">
              <div className="video-popup">
                <img
                  src={require("../../src/assets/video_thumbnail_3.webp")}
                  alt="video"
                ></img>
                <Link onClick={() => setModalShow(true)}>
                  <AiFillPlayCircle />
                </Link>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="hiw-second-section">
        <Container>
          <Row>
            <Col lg={6}>
              <h3>How to get started:</h3>
              <Box sx={{ maxWidth: 600 }}>
                {/* <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label} className="hiw-step-label">
                      <StepLabel
                        optional={
                          index === 2 ? (
                            <Typography variant="caption">Last step</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              className="hiw-next-btn"
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Continue"}
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper> */}
                {steps.map((step, index) => (
                  <Stepper orientation="vertical" key={index}>
                    <Step key={index} className="hiw-step-label">
                      <StepLabel icon={index + 1}>
                        <Typography>
                          <b>{step.label}</b>
                        </Typography>
                      </StepLabel>
                      <StepContent
                        className={
                          index === steps.length - 1 ? "last-step-content" : ""
                        }
                      >
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  </Stepper>
                ))}
              </Box>
            </Col>
            <Col md={6}>
              <img src={HowitWork} alt="img" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`vs-section`}>
        <div className="container">
          <div className="py-4">
            <h2>A better partner for seller leads.</h2>
          </div>
          <div className="hp-content">
            <div className="left">
              <div className="text-end content-desc">
                <p className="firstContent">
                  <img src={orgLogo} width={200} />
                </p>
                <p>
                  Exclusive Zip Codes{" "}
                  <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  No Contracts <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  No Referral Fees <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  Google Pay Per Click Sponsored Ads{" "}
                  <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  Free SEO Traffic <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  Option To Increase Lead Flow{" "}
                  <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
              </div>
            </div>
            <div className="middle">
              <div className="line"></div>
              <div className="vs-circle">VS</div>
            </div>
            <div className="right">
              <div className="text-start content-desc">
                <p className="firstContent">
                  <h4>Other Lead Gen Platforms</h4>
                </p>
                <p></p>
                <p>
                  <IoMdCloseCircle /> Shared Zip Codes
                </p>
                <p>
                  <IoMdCloseCircle /> Annual Contracts With Cancellation
                  Penalties{" "}
                </p>
                <p>
                  <IoMdCloseCircle /> Hefty Referral Fees
                </p>
                <p>
                  <IoMdCloseCircle /> No Pay Per Click Ads
                </p>
                <p>
                  <IoMdCloseCircle /> No SEO
                </p>
                <p>
                  <IoMdCloseCircle /> Fixed Lead Flow
                </p>
              </div>
            </div>
          </div>
          <div className="p-2 getStarted-btn">
            <button
              type="button"
              onClick={() => setModalShowAddress(true)}
              className="find-btn custom-button-css custom-button-orange-bg mt-3"
            >
              GET STARTED
            </button>
          </div>
        </div>
      </div>

      <div className="hiw-third-section agentsign-third-section">
        <Container>
          <Row>
            <Col md={12}>
              <h1>Frequently Asked Questions</h1>
              <Accordion defaultActiveKey={"0"} className="accordian-container">
                {faqsList.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header className="acordian-btn">
                      {item.question}
                    </Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
          <Modal
            show={modalShowAddress}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="p-3"
          >
            <Modal.Body className="p-5">
              <h5 className="text-left">Search agent</h5>
              <Form onSubmit={handleSubmitzPro}>
                <InputGroup
                  className="mt-3 zip-search-input-content howitwork"
                  style={{
                    width: "100%",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                >
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="position-relative homeAddress">
                        <input
                          {...getInputProps({
                            placeholder: "Search address",
                            className: "form-control",
                            style: { fontSize: "1.2rem" },
                          })}
                          required
                        />

                        {suggestions.length > 0 && (
                          <div
                            className="position-absolute start-0 mt-2"
                            style={{
                              background: "#fff",
                              border: "1px solid #ced4da",
                              borderRadius: "0.25rem",
                              zIndex: 1000,
                              width: "550px",
                            }}
                          >
                            <ul className="list-group">
                              {loading ? (
                                <li className="list-group-item">Loading...</li>
                              ) : (
                                suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                  };

                                  return (
                                    <li
                                      className="list-group-item"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </li>
                                  );
                                })
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <BsGeoAltFill />
                  <Form.Control
                    type="number"
                    name="agent_zip_code"
                    placeholder="Zip Code"
                    className="shadow-none zip_number"
                    value={formData.agent_zip_code}
                    onChange={handleChange}
                    required
                  />
                </InputGroup>
                <h5 className="text-left mt-4 mb-2">
                  Fill your contact details
                </h5>
                <Form.Control
                  className="mt-3 mb-4"
                  name="name"
                  size="lg"
                  type="text"
                  placeholder="First and Last Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control
                  className="mt-4 mb-4"
                  name="phone"
                  size="lg"
                  type="text"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                {/* <div className='or'><span>OR</span></div> */}
                <Form.Control
                  className="mt-1 mb-4"
                  name="email"
                  size="lg"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {/* <Form.Control
                  className="mt-1 mb-4"
                  name="address"
                  size="lg"
                  type="text"
                  required
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                />
                <Form.Control
                  className="mt-1 mb-4 zip_number"
                  name="zip_code"
                  size="lg"
                  type="number"
                  placeholder="Zip code"
                  value={formData.zip_code}
                  onChange={handleChange}
                  required
                /> */}
                <div className="d-flex justify-content-start">
                  <button
                    type="submit"
                    className="custom-button-css custom-button-orange-bg"
                  >
                    Submit
                  </button>
                  &nbsp;
                  <button
                    variant="danger"
                    className="custom-button-css custom-button-red-bg"
                    onClick={() => setModalShowAddress(false)}
                  >
                    Close
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </Layout>
  );
};

export default Howitwork;
