import React, { useState, useEffect } from "react";
import Agentlayout from "../../components/Agent/Agentlayout";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FiArrowLeft, FiDownload } from "react-icons/fi";
import { CiDiscount1 } from "react-icons/ci";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "../../Style/custom.css";
import "../../Style/Agents/ad-sponsor.css";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TimeAgo from "react-timeago";
import stripeLogo from "../../assets/stripe.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../components/Loader";
import favicon from "../../assets/favicon.ico";
import { Card } from "react-bootstrap";
import StripePayment from "./StripePayment_clicks";
import byStripeImg from "../../assets/by-stripe.svg";
const AgentOrders = () => {
  const navigate = useNavigate();
  const [ordersList, setOrderlist] = useState([]);
  const [orderSuccess, setOrderSuccess] = useState();
  const authToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [guaranteedClicks, setGuaranteedClicks] = useState(10);
  const costPerClick = 1.09;
  const totalCost = (guaranteedClicks * costPerClick).toFixed(2);
  const [data, setdata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showProduct, setProductshow] = useState([]);

  const [data_old, setdata_old] = useState([]);
  const [formData, setFormData] = useState({
    client_id: "",
    client_secret: "",
    redirecturl: "",
    refresh_token: "",
    developer_token: "",
    customer_id: "",
    manager_id: "",
    api_key: ""
  });
  const handleIncrement = () => {
    setGuaranteedClicks((prevClicks) => prevClicks + 1);
  };
  const handleOpenModal = () => {
    setShowModal(true);
    handleZipCloseModal();

  };

  const handleCloseModal = () => {
    setShowModal(false);
    setProductshow([]);
    setGuaranteedClicks(10);

  };
  const handleDecrement = () => {
    if (guaranteedClicks > 0) {
      setGuaranteedClicks((prevClicks) => prevClicks - 1);
    }
  };

  const handlOpenMoreClick = (product) => {
    setModal(true);
    setProductshow(product);
    setGuaranteedClicks(10);

  };

  const handleZipCloseModal = () => {
    setModal(false);
    // setGuaranteedClicks(10);
  };

  const handleDownloadClick = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/generateInvoice/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        setLoading(false);
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_BASE_URL}${response.data.filepath}`;
        link.download = `invoice_${id}.pdf`;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
        }, 150);
      } else {
        setLoading(false);
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const handleConfirmBox = (id, order_id) => {
    confirmAlert({
      title: <span className="orange-title">Confirm</span>,
      message: "Are you sure you want to cancel product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleSuProductDelete(id, order_id);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleConfirmBoxCancel = (order_id) => {
    confirmAlert({
      title: <span className="orange-title">Confirm</span>,
      message: "Are you sure you want to cancel Subscription ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleCancelSubscription(order_id);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleCancelSubscription = async (order_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/cancel-subscription/${order_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        NotificationManager.success(
          "Subscription",
          response.data.message,
          3000
        );
        fetchData();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const handleSuProductDelete = async (id, order_id) => {
    setLoading(true);
    console.log(id);
    console.log(order_id);
    const filteredData = data_old.result.filter(item => item.order_id === order_id && item.camp_id != null);
    console.log(filteredData);
    const groupid = filteredData[0].ads_id.split('/')[3].split('~')[0];
    const adid = filteredData[0].ads_id.split('/')[3].split('~')[1].replace(/\D/g, '');
    console.log(groupid);
    console.log(adid);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/product-cancel/${id}/${order_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        setLoading(false);
        NotificationManager.success(
          "Subscription",
          response.data.message,
          3000
        );
        const response1 = await axios.post(
          `${process.env.REACT_APP_BASE_URL}ads/change-status/${adid}/${3}`,
          { formData: formData, status: 3, group_id: groupid, id: adid },
          {
            headers: { Authorization: `Bearer ${authToken}` },
            withCredentials: true,
          }
        );
        console.log(response1);
        fetchData();

      } else {
        setLoading(false);
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  useEffect(() => {
    const msg = localStorage.getItem("orderSuccess");
    if (msg && msg !== "") {
      NotificationManager.success("Order", msg, 4000);
    }
    const timeoutId = setTimeout(() => {
      localStorage.removeItem("orderSuccess");
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData();

  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/orders`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        console.log(response);
        setOrderlist(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const [activeTab, setActiveTab] = useState("0");
  const handleLiClick = (tab) => {
    setActiveTab(activeTab === tab ? null : tab);
  };

  const fontStyle = {
    color: "#7f7f7f",
    fontWeight: "700",
  };

  const amtFontColor = {
    color: "#ff6c2c",
    fontWeight: "700",
  };



  const extractData = (zipCode) => {
    const filtered = data.filter((item) => {
      const match1 = item.campaign.name.match(/\b\d{5}\b/);

      return match1 && parseInt(match1[0]) == zipCode;
    });
    console.log(filtered);
    return filtered;
  };
  const totalClicksMap = {};

  // Calculate total clicks for each order_id

  return (
    <Agentlayout>
      <div className="account-header-container">
        {loading && <Loader />}
        <Container>
          <NotificationContainer />
          <Row>
            <Col>
              <div className="account-header">
                <div className="d-flex justify-content-between">
                  <div className="mt-2">
                    <h1>Zip Codes</h1>
                  </div>
                  <div>
                    <Button className="buymoreBtn custom-button-css custom-button-orange-bg">
                      <Link to={"/agent/purchase-zip"}>Purchase Zip Codes</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="account-forms m-2">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="account-content">
                {ordersList.length > 0 && (
                  <div>
                    {ordersList.map((result, index) => (
                      <Accordion defaultActiveKey={activeTab} key={index}>
                        <Accordion.Item
                          eventKey={index.toString()}
                          className="accordian-items"
                        >
                          <Accordion.Header>
                            <p>
                              Total amount : &nbsp;<b>${result.total}</b>
                              &nbsp;&nbsp;
                            </p>
                            {/* <span className="status-tag mb-3">
                              {" "}
                              <TimeAgo date={result.created_at} />
                            </span>
                            &nbsp; */}
                            <span className="status-tag mb-3" title="Discount">
                              <CiDiscount1 size={20} />{" "}
                              {result.discount ? `$${result.discount}` : `$0`}
                            </span>
                            <span
                              className="status-tag mb-3"
                              title="Download invoice"
                              onClick={() => handleDownloadClick(result.id)}
                            >
                              <FiDownload size={20} />
                            </span>
                            {result.transaction &&
                              result.transaction.invoice_pdf !== "" &&
                              result.transaction.invoice_pdf !== null &&
                              result.status !== 2 && (
                                <span
                                  className="status-tag mb-3"
                                  title="Download stripe invoice"
                                >
                                  <a
                                    href={result.transaction.invoice_pdf}
                                    target="__blank"
                                  >
                                    <img
                                      src={stripeLogo}
                                      alt="stripe logo"
                                      height={20}
                                    />
                                  </a>
                                </span>
                              )}
                            {/* {result.status === 2 ? (
                              <Button
                                className="mx-2 btn-sub find-btn mb-3 custom-button-orange-bg btn-dark"
                                disabled
                              >
                                <b>Subscription Cancelled</b>
                              </Button>
                            ) : (
                              <Button
                                className="mx-2 btn-sub find-btn mb-3 custom-button-orange-bg"
                                onClick={() =>
                                  handleConfirmBoxCancel(result.id)
                                }
                              >
                                <b>Cancel Subscription</b>
                              </Button>
                            )} */}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="imp-doc-accordian">
                              {result.orderProduct.length > 0 && (
                                <div className="cartItem mb-5">
                                  <Row className="pb-3 border-bottom">
                                    <Col lg={2} className="imp-doc-first-col">
                                      <p>
                                        <b>City</b>
                                      </p>
                                    </Col>
                                    <Col lg={2} className="imp-doc-second-col">
                                      <p>
                                        <b>Zip code</b>
                                      </p>
                                    </Col>
                                    <Col lg={1} className="imp-doc-third-col">
                                      <p>
                                        <b>Price</b>
                                      </p>
                                    </Col>
                                    <Col lg={2} className="imp-doc-third-col">
                                      <p>
                                        <b>Subscription Start</b>
                                      </p>
                                    </Col>
                                    <Col lg={2} className="imp-doc-third-col">
                                      <p>
                                        <b>Subscription End</b>
                                      </p>
                                    </Col>
                                    <Col lg={1} className="imp-doc-third-col">
                                      <p>
                                        <b>Action</b>
                                      </p>
                                    </Col>
                                    <Col lg={2} className="imp-doc-third-col">
                                      <p>
                                        <b>Remark</b>
                                      </p>
                                    </Col>
                                  </Row>
                                  {result.orderProduct.map((product, index) => (
                                    <>
                                      <Row
                                        className="pt-3 border-bottom align-items-center"
                                        key={index}
                                      >
                                        <Col lg={2}>
                                          <p>{product.city}</p>
                                        </Col>
                                        <Col lg={2}>
                                          <p>{product.zip_code}</p>
                                        </Col>
                                        <Col lg={1}>
                                          <p>${product.price}</p>
                                        </Col>
                                        <Col lg={2}>
                                          <p>
                                            {formatDate(product.start_date)}
                                          </p>
                                        </Col>
                                        <Col lg={2}>
                                          {/* <p>{product.end_date}</p> */}
                                          <p>-</p>
                                        </Col>
                                        <Col lg={1}>
                                          {product.status === 2 ||
                                            (product.isCancel !== "" &&
                                              product.isCancel !== null) ? (
                                            <Button
                                              className="btn-sub p-2 find-btn mb-3 custom-button-orange-bg btn-dark"
                                              disabled
                                            >
                                              <b>Cancelled</b>
                                            </Button>
                                          ) : (
                                            <Button
                                              className="btn-sub p-2 find-btn mb-3 custom-button-orange-bg"
                                              onClick={() =>
                                                handleConfirmBox(
                                                  product.id,
                                                  result.id
                                                )
                                              }
                                            >
                                              <b>Cancel</b>
                                            </Button>
                                          )}
                                        </Col>
                                        <Col lg={2}>
                                          <p className="mx-2 smallFont">
                                            {product.isCancel !== "" &&
                                              product.isCancel === 1 && (
                                                <span>
                                                  End at {product.end_date}
                                                </span>
                                              )}
                                            {product.isCancel !== "" &&
                                              product.isCancel === 0 && (
                                                <span>
                                                  Ended by admin on{" "}
                                                  {product.cancel_at}
                                                </span>
                                              )}
                                          </p>
                                        </Col>
                                      </Row>
                                      <>
                                        {Object.keys(totalClicksMap).map(
                                          (order_id) => {
                                            const totalClicks =
                                              totalClicksMap[order_id];
                                            return (
                                              // eslint-disable-next-line eqeqeq
                                              result.id == order_id ?
                                                <Card
                                                  key={order_id}
                                                  className="d-flex my-4 p-3"
                                                >
                                                  <Row>
                                                    <Col lg={6}>
                                                      <h5 style={fontStyle}>
                                                        Guaranteed Pay-Per-Click
                                                        Views
                                                      </h5>
                                                      <h2
                                                        className="text-start"
                                                        style={amtFontColor}
                                                      >
                                                        {totalClicks}
                                                      </h2>
                                                    </Col>
                                                    <Col lg={6}>
                                                      <h5 style={fontStyle}>
                                                        Remaining Pay-Per-Click
                                                        Views
                                                      </h5>
                                                      <h2
                                                        className="text-start"
                                                        style={amtFontColor}
                                                      >
                                                        0
                                                      </h2>
                                                    </Col>
                                                  </Row>
                                                </Card>
                                                : null
                                            );
                                          }
                                        )}
                                      </>

                                      {/* {data_old.result.map((item, index) =>
                                        item.order_id == result.id ? (
                                          <Card className="d-flex my-4 p-3">
                                            <Row>
                                              <Col lg={6}>
                                                <h5 style={fontStyle}>
                                                  Guaranteed Pay-Per-Click Views
                                                </h5>
                                                <h2
                                                  className="text-start"
                                                  style={amtFontColor}
                                                >
                                                  {0 + item.clicks}
                                                </h2>
                                              </Col>
                                              <Col lg={6}>
                                                <h5 style={fontStyle}>
                                                  Remaining Pay-Per-Click Views
                                                </h5>
                                                <h2
                                                  className="text-start"
                                                  style={amtFontColor}
                                                >
                                                  0
                                                </h2>
                                              </Col>
                                            </Row>
                                          </Card>
                                        ) : null
                                      )} */}
                                      <Row className="row-gap-3">
                                        <Col lg={12} className="pt-2">
                                          <h4
                                            className="text-start"
                                            style={amtFontColor}
                                          >
                                            Ad Creative
                                          </h4>
                                        </Col>
                                        <Col lg={9}>
                                          <Card className="p-3 shadow">
                                            <h6
                                              className="mb-0 "
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Sponsored
                                            </h6>
                                            <div className="sponsor-ad mt-1">
                                              <img
                                                src={favicon}
                                                alt="Favicon"
                                                className="favicon"
                                              />
                                              <div className="ad-content1">
                                                <div className="site-title">
                                                  listelligent.com
                                                </div>
                                                <div className="ad-url">
                                                  <a href="https://www.listelligent.com/state/texas/bexar/san-antonio">
                                                    https://www.listelligent.com
                                                    &gt; texas &gt; bexar &gt;
                                                    san-antonio
                                                  </a>
                                                </div>
                                              </div>
                                              <a
                                                href="https://www.listelligent.com/state/texas/bexar/san-antonio"
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                              >
                                                <h3 className="ad-heading1 text-start">
                                                  Sell Your Home for 1% Listing
                                                  Commission
                                                </h3>
                                              </a>
                                              <p>
                                                Hassle Free Search. Insert your
                                                address and connect with a pro
                                                right away! No long
                                                questionnaires, spam calls, junk
                                                emails or any other nonsense.
                                              </p>
                                            </div>
                                          </Card>
                                        </Col>
                                        <Col
                                          lg={3}
                                          style={{ alignContent: "center" }}
                                        >
                                          <Button
                                            className="find-btn custom-button-css custom-button-orange-bg"
                                            onClick={() =>
                                              handlOpenMoreClick(product)
                                            }
                                          >
                                            Buy More Clicks
                                          </Button>
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                </div>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))}
                  </div>
                )}
                {ordersList.length === 0 && (
                  <div className="noLeads text-center mt-3">
                    <h4>Welcome to the Listelligent family.</h4>
                    <p>
                      You don't have any Orders{" "}
                      <Button className="find-btn custom-button-css custom-button-orange-bg">
                        <Link
                          to={"/agent/purchase-zip"}
                          className="text-white"
                          style={{ textDecoration: "none" }}
                        >
                          Buy zip code
                        </Link>
                      </Button>
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={modal} onHide={handleZipCloseModal} size="lg" centered>
        <Modal.Header style={{ display: "block" }}>
          <Modal.Title className="text-center">
            <b>Buy More Clicks</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-4">
          <Row>
            <Col md={5} className="mb-5">
              <h3 className="text-center lh-lg" style={fontStyle}>
                Cost Per Click
              </h3>
              <h3 className="text-center" style={amtFontColor}>
                ${costPerClick.toFixed(2)}
              </h3>
              <div className="mt-4">
                <h3 className="text-center lh-lg" style={fontStyle}>
                  Guaranteed Clicks
                </h3>
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={handleDecrement}
                    style={{
                      background: "#000",
                      fontSize: "20px",
                      color: "#fff",
                      padding: "10px",
                      borderColor: "#000",
                      marginRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Button>
                  <h3
                    className="text-center"
                    style={{ ...amtFontColor, alignContent: "end" }}
                  >
                    {guaranteedClicks}
                  </h3>
                  <Button
                    onClick={handleIncrement}
                    style={{
                      background: "#000",
                      fontSize: "20px",
                      color: "#fff",
                      padding: "10px",
                      borderColor: "#000",
                      marginLeft: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={2}></Col>
            <Col md={5} style={{ alignContent: "end", textAlign: "center" }}>
              <div className="mb-5">
                <h3 className="text-center lh-lg" style={fontStyle}>
                  Total Cost
                </h3>
                <h3 className="text-center" style={amtFontColor}>
                  ${totalCost}
                </h3>
              </div>
              <div>
                <Button
                  onClick={handleOpenModal}
                  className="text-center find-btn custom-button-css custom-button-orange-bg"
                >
                  Buy
                </Button>
                <p className="p-2">This is not a recurring charge.</p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            className="custom-button-css custom-button-red-bg"
            onClick={handleZipCloseModal}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="md"
        centered
        className="addBalanceModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy More Clicks Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StripePayment
            promoCode={""}
            product={showProduct}
            total={totalCost}
            cartItems={guaranteedClicks}
          />
        </Modal.Body>
        <Modal.Footer>
          <img
            src={byStripeImg}
            alt="Success"
            style={{ width: "auto", height: "25px", margin: "0 auto" }}
          />
          {/* <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button> */}
        </Modal.Footer>
      </Modal>
    </Agentlayout>
  );
};

export default AgentOrders;
