import React, { useState, useEffect } from "react";
import "../Style/home.css";
import "../Style/custom.css";
import Layout from "../components/Layouts/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import usStates from "./usStates";
import Loader from "../components/Loader";
import ConstructionPage from "./ConstructionPage";
import { Helmet } from "react-helmet";

const StatePage = () => {
  const { state } = useParams();
  const navigate = useNavigate();
  const [stateInfo, setStateInfo] = useState({});
  const [districtList, setDistrictList] = useState([]);
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("token");
  const [splitContent, setSplitContent] = useState({});
  const statesPerColumn = Math.ceil(usStates.length / 4);

  const getStatePageData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}communities/${state}`
      );
      setLoading(false);
      if (response.status) {
        if (response.data.data) {
          setDistrictList(response.data.data.districtList);
          setStateInfo(response.data.data.stateInfo);
          const { previewContent, remainingContent } = getContentSplit(
            response.data.data.stateInfo.content,
            900
          );
          setSplitContent({ previewContent, remainingContent });
        }
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };
  useEffect(() => {
    getStatePageData();
    window.scrollTo(0, 0);
  }, []);

  const getContentSplit = (content, charLimit) => {
    if (content.length <= charLimit) {
      return { previewContent: content, remainingContent: "" };
    }

    let sliceEnd = content.lastIndexOf(".", charLimit);
    let safeSliceEnd =
      sliceEnd > charLimit / 2 ? sliceEnd : content.indexOf(".", charLimit);
    if (safeSliceEnd === -1) safeSliceEnd = content.length;

    const previewContent = content.slice(0, safeSliceEnd + 1);
    const remainingContent = content.slice(safeSliceEnd + 1);

    return { previewContent, remainingContent };
  };
  if (loading || !stateInfo || !districtList) {
    return <Loader />;
  }
  return (
    <Layout>
      <Helmet>
        <title>{`List Your Home For 1% In ${stateInfo.name} | Listelligent`}</title>
        {/* <meta name="description" content={description} /> */}
      </Helmet>
      {loading && <Loader />}      
      <div className="first-section">
        <NotificationContainer />
        <Container>
          <Row className="mt-3 first-inner-section mt-5">
            <Col>
              <Card className="mb-4 border-0">
                <Card.Body>
                  <Card.Title className="third-sec-heading">
                    <h1 className="orangeColorText">
                      List Your Home For 1% In {stateInfo.name}
                    </h1>
                  </Card.Title>
                  <Row style={{ textAlign: "justify" }}>
                    <Col md={8}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitContent.previewContent,
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      {stateInfo.img ? (
                        <img
                          alt="state-img"
                          className="img-fluid"
                          src={`${process.env.REACT_APP_BASE_URL}assets/state/${stateInfo.img}`}
                        />
                      ) : (
                        <img
                          alt="state-img"
                          className="img-fluid"
                          src={require("../assets/no-image.png")}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitContent.remainingContent,
                        }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="border-0">
                <Card.Body>
                  <Card.Title className="third-sec-heading communitiesHeading">
                    <h2 className="orangeColorText">
                      Districts In {stateInfo.name}
                    </h2>
                  </Card.Title>
                  <Row xs={1} md={4} className="g-4 mt-3 txtBlack">
                    {districtList.map((data, idx) => (
                      <Col key={idx} className="m-0 text-center p-1">
                        <Link to={data.slug}>
                          <b>{data.name}</b>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default StatePage;
