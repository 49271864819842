import React, { useState, useEffect } from "react";
import "../../Style/home.css";
import Agentlayout from "../../components/Agent/Agentlayout";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { BsCart, BsTrash, BsInfoCircle } from "react-icons/bs";
import { AiOutlineWarning } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import StripePayment from "./StripePayment";
import byStripeImg from "../../assets/by-stripe.svg";
// import MyMap from "./MyMap";
// import MyMap2 from "./MyMap2";
// import MapboxComponent from "./MapboxComponent";
import NewMap from "./NewMap";

const PurchaseZip = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [searchResults, setSearchResults] = useState([]);


  const [cartItems, setCartItems] = useState([]);
  const authToken = localStorage.getItem("token");
  const [cartTotal, setCartTotal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [zipDetails, setZipDetails] = useState([]);

  const handleNormalZipClick = (zip) => {
    getZipCodeDetails(zip);
  };

  const handleZipCloseModal = () => {
    setModalOpen(false);
  };
  

  useEffect(() => {
    if (searchInput.trim() !== "") {
      const delayDebounceFn = setTimeout(() => {
        handleSearch();
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchResults([]);
    }
  }, [searchInput]);

  const [fetchCartItems, setFetchCartItems] = useState(() => async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/getCart`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      setCartItems(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  });

  useEffect(() => {
    fetchCartItems();
  }, [fetchCartItems]);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}zipcode/search?q=${searchInput}`
      );

      setSearchResults(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const handleAddToCart = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/zip/addtocart/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        NotificationManager.success("Success", response.data.message, 3000);
        handleZipCloseModal();
        setCartItems(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const getZipCodeDetails = async (zip_code) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}get-details/${zip_code}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        if (response.data && response.data.data) {
          setZipDetails(response.data.data);
          setModalOpen(true);
        } else {
          NotificationManager.error(
            "Error",
            "Unexpected response format",
            3000
          );
        }
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error(
          "Error",
          "An unexpected error occurred",
          3000
        );
      }
    }
  };

  const handleRemoveItem = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/item-remove/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        NotificationManager.success("Success", response.data.message, 3000);
        setCartItems(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    const cartTotalCal = cartItems.reduce(
      (total, item) => total + parseFloat(item.zipCode.prize),
      0
    );
    const formattedTotal = cartTotalCal.toFixed(2);
    setCartTotal(formattedTotal);
  }, [cartItems]);

  const removePromoCode = () => {
    const cartTotalCal = cartItems.reduce(
      (total, item) => total + parseFloat(item.zipCode.prize),
      0
    );
    const formattedTotal = cartTotalCal.toFixed(2);
    setCartTotal(formattedTotal);
    setPromoCode("");
  };

  const applyPromoCode = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/promo-code/${promoCode}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        const percentage = response.data.data.percentage;
        const discountAmount = (percentage / 100) * cartTotal;
        const newTotal = cartTotal - discountAmount;
        setCartTotal(newTotal.toFixed(2));
        NotificationManager.success("Success", "Promo code applied.", 3000);
      } else {
        removePromoCode();
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        console.log("invvvlvl");
        removePromoCode();
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const handleConfirmBox = () => {
    confirmAlert({
      title: <span className="orange-title">Confirm</span>,
      message: "Are you sure you want to Place order ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handlePlaceOrder();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const handlePlaceOrder = async () => {  
    if (cartTotal > 0) {
      handleOpenModal();
    } else {
      const paymentIntent = {
        paymentIntent: {
          cart_total: cartTotal,
          id: "Your purchase is fully discounted.",
          promoCode: promoCode,
          amount: 0,
          status: "100% Discount",
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}agent/cart/free/placeOrder`,
          paymentIntent,
          {
            headers: { Authorization: `Bearer ${authToken}` },
            withCredentials: true,
          }
        );
        if (response.status) {
          console.log(response.data);
          // handleTest(response.data.data);
          // NotificationManager.success("Success", response.data.message, 3000);
          // const userData = JSON.parse(localStorage.getItem("userData"));
          // userData.activeZipcode = cartItems.length;
          // localStorage.setItem("userData", JSON.stringify(userData));
          // localStorage.setItem("orderSuccess", response.data.message);
          // navigate("/agent/orders");
          // fetchCartItems();
        } else {
          NotificationManager.error("Error", response.data.message, 3000);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        } else {
          NotificationManager.error("Error", error.response.data.message, 3000);
        }
      }
      //
    }
  };

  const fontStyle = {
    color: "#7f7f7f",
    fontWeight: "700",
  };

  const amtFontColor = {
    color: "#ff6c2c",
  };
 

 

  return (
    <Agentlayout>
      <div className="purchaseZip">
        <Container>
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="md"
            centered
            className="addBalanceModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Monthly Subscription Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <StripePayment
                promoCode={promoCode}
                total={cartTotal}
                cartItems={cartItems}
              />
            </Modal.Body>
            <Modal.Footer>
              <img
                src={byStripeImg}
                alt="Success"
                style={{ width: "auto", height: "25px", margin: "0 auto" }}
              />
              {/* <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button> */}
            </Modal.Footer>
          </Modal>
          <NotificationContainer />
          <Row>
            <Col>
              <div className="account-header">
                <h1>Zip code</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="first-section">
        <Container>
          <Row>
            <Col md={12} className="mb-2">
              <NewMap
                handleAddToCart={(id) => handleAddToCart(id)}
                handleNormalZipClick={handleNormalZipClick}
              />
            </Col>
          </Row>
          <NotificationContainer />
          {/* <Row className="mt-3 mb-5 first-inner-section">
            <div className="zip-search-input">
              <InputGroup
                className="mt-3 zip-search-input-content"
                style={{ width: "100%" }}
              >
                <Form.Control
                  placeholder="Search zip code and add to cart"
                  aria-label="Zip code"
                  aria-describedby="basic-addon2"
                  className="shadow-none"
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button className="find-btn">Search</Button>
              </InputGroup>
            </div>
          </Row> */}
          {searchResults.length > 0 && (
            <div className="cartItem mb-5">
              <Row>
                {searchResults.map(
                  (result, index) =>
                    !cartItems.some(
                      (cartItem) => cartItem.zip_id === result.id
                    ) && (
                      <Col key={index} xs={6} md={4} lg={2}>
                        {/* Bootstrap Card for each product */}
                        <Card className="mb-4" key={index}>
                          <Card.Body className="text-center">
                            <Card.Title>{result.city}</Card.Title>
                            <Card.Text>
                              <b>${result.prize}</b>
                            </Card.Text>
                            <Card.Text>{result.zip_code}</Card.Text>
                            {result.isSold === 1 ? (
                              <Button
                                variant="warning"
                                className="text-white"
                                disabled
                              >
                                Sold
                              </Button>
                            ) : (
                              <Button
                                className="find-btn"
                                onClick={() => handleAddToCart(result.id)}
                              >
                                <BsCart />
                              </Button>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                )}
              </Row>
            </div>
          )}

          {cartItems.length === 0 && (
            <Row>
              <Col sm={12}>
                <hr />
                <h4>Your cart data will be shown here</h4>
                <hr />
              </Col>
            </Row>
          )}

          {cartItems.length > 0 && (
            <div className="cartItem mb-5">
              <hr />
              <Row>
                <Col md={8}>
                  <h3>Your cart items</h3>
                  <hr />
                  <Row>
                    {cartItems.map((result, index) => (
                      <Col xs={6} md={4} lg={3} key={index}>
                        <Card className="mb-4 h-100 d-flex flex-column">
                          <Card.Body className="text-center flex-grow-1">
                            <Card.Title>{result.zipCode.city}</Card.Title>
                            <Card.Text>
                              <b>${result.zipCode.prize}</b>
                            </Card.Text>
                            <Card.Text>{result.zipCode.zip_code}</Card.Text>
                          </Card.Body>
                          <Button
                            className="find-btn rounded-pill custom-button-orange-bg"
                            onClick={() => handleRemoveItem(result.id)}
                          >
                            <BsTrash />
                          </Button>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col md={4}>
                  <h3>Order summary</h3>
                  <hr />
                  <Row>
                    <Col md={12} className="d-inline-flex">
                      <Form.Control
                        type="text"
                        name="promo_code"
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        placeholder="Enter promo code"
                        className="form-control shadow-none form-input"
                      />
                      {promoCode !== "" && (
                        <>
                          <Button
                            className="find-btn rounded-pill"
                            size="md"
                            onClick={() => applyPromoCode()}
                          >
                            Apply
                          </Button>
                          &nbsp;
                          <Button
                            variant="danger"
                            className="rounded-pill"
                            onClick={() => removePromoCode()}
                          >
                            <BsTrash />
                          </Button>
                        </>
                      )}
                    </Col>
                    <Col md={12} className="mt-2">
                      <Button
                        className="find-btn custom-button-css custom-button-orange-bg orange-bg-non-width"
                        onClick={() => handleConfirmBox()}
                      >
                        Order now <b>${cartTotal}</b>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Container>
        <Modal show={modalOpen} onHide={handleZipCloseModal} size="xl" centered>
          <Modal.Header style={{ display: "block" }}>
            <Modal.Title>
              <h2 className="text-center" style={amtFontColor}>
                Subscribe To {zipDetails.zip_code}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {zipDetails.isSold ? (
              <Row>
                <Col md={2}>
                  <AiOutlineWarning size={90} color="red" />
                </Col>
                <Col md={10}>
                  {" "}
                  <h5 className="pt-2">
                    Sold limit exceeded for this {zipDetails.zip_code} zip code.
                    Cannot add more to the cart. Please check the cart data.
                  </h5>
                </Col>
              </Row>
            ) : (
              <div className="p-4">
                <Row>
                  {/* <Col md={2}>
                    <BsCart size={90} color="#f05b1b" />
                  </Col> */}
                  <Col md={12}>
                    <h5>
                      <b>
                        You will be the exclusive agent profile for the zip code
                        of {zipDetails.zip_code}. You will be charged a
                        reoccurring fee on the date you subscribe.
                      </b>
                    </h5>
                    {/* <p>
                      Today, you will pay ${zipDetails.prize} for zip code{" "}
                      {zipDetails.zip_code}.
                    </p>
                    <p>
                      Click <BsCart /> to continue with your purchase.
                    </p> */}
                  </Col>
                </Row>
                {/* <Row>
                  <Col md={12}>
                    <div className="text-center">
                      <BsInfoCircle />{" "}
                      <span className="text-warning">
                        This zip code has a total of{" "}
                        {process.env.REACT_APP_SOLD_LIMIT} slots, and{" "}
                        {process.env.REACT_APP_SOLD_LIMIT - zipDetails.sold}{" "}
                        slots remain.
                      </span>
                    </div>
                  </Col>
                </Row> */}
                <Row className="pt-5">
                  <Col md={6} className="pb-3">
                    <h3 style={fontStyle}>Monthly Subscription</h3>
                    <h2 className="text-start" style={amtFontColor}>
                      ${zipDetails.prize}
                    </h2>
                  </Col>
                  <Col md={6}>
                    <h3 style={fontStyle}>
                      Approximately Profile Views/Clicks
                    </h3>
                    <div className="d-flex">
                      <h2 style={amtFontColor}>10</h2>
                      <h5 className="px-3">
                        Top up for more leads starting at $1.09 per click lead.
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
           
          </Modal.Body>
          <Modal.Footer>
            {!zipDetails.isSold && (
              <Button
                className="find-btn custom-button-css custom-button-orange-bg"
                title="Add to cart"
                type="submit"
                onClick={() => handleAddToCart(zipDetails.id)}
                // disabled={cartItems.some((item) => item.zip_id === zipDetails.id)}
                disabled={zipDetails.isSold}
              >
                Subscribe
              </Button>
            )}
            <Button
              variant="secondary"
              className="custom-button-css custom-button-red-bg"
              onClick={handleZipCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Agentlayout>
  );
};

export default PurchaseZip;
