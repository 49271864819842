import React, { useState, useEffect } from "react";
import "../Style/home.css";
import "../Style/custom.css";
import Layout from "../components/Layouts/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CloseButton } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BiInfoCircle } from "react-icons/bi";
import InputGroup from "react-bootstrap/InputGroup";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import usStates from "./usStates";
import Loader from "../components/Loader";
import ConstructionPage from "./ConstructionPage";
import { BsGeoAltFill } from "react-icons/bs";
import dummyAgent from "../assets/agreement.png";
import { Helmet } from "react-helmet";

const DistrictPage = () => {
  const { state, district } = useParams();
  const navigate = useNavigate();
  const [districtInfo, setDistrictInfo] = useState({});
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("token");
  const [splitContent, setSplitContent] = useState({});
  const statesPerColumn = Math.ceil(usStates.length / 4);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const storedFormDataString = localStorage.getItem("formData");
  const storedFormData = storedFormDataString
    ? JSON.parse(storedFormDataString)
    : null;
  const [cookies, setCookie] = useState(storedFormData);
  const [formData, setFormData] = useState({
    name: cookies ? cookies["name"] : "",
    phone: cookies ? cookies["phone"] : "",
    email: cookies ? cookies["email"] : "",
    address: cookies ? cookies["address"] : "",
    zip_code: cookies ? cookies["zip_code"] : "",
    agent_zip_code: cookies ? cookies["agent_zip_code"] : "",
    state: cookies ? cookies["state"] : "",
  });
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [defaultRange, setRange] = useState(1650000);
  const [onePer, setOnePer] = useState(0);
  const [twoPer, setTwoPer] = useState(0);
  const [threePer, setThreePer] = useState(0);
  useEffect(() => {
    setOnePer((defaultRange * 1) / 100);
    setTwoPer((defaultRange * 2) / 100);
    setThreePer((defaultRange * 3) / 100);
  }, [defaultRange]);

  const getStatePageData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}communities/${state}/${district}`
      );
      setLoading(false);
      if (response.status) {
        if (response.data.data) {
          setDistrictInfo(response.data.data.districtInfo);
          setCityList(response.data.data.citytList);
          setAgentList(response.data.data.agentList);
          const { previewContent, remainingContent } = getContentSplit(
            response.data.data.districtInfo.content,
            900
          );
          setSplitContent({ previewContent, remainingContent });
        }
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };
  useEffect(() => {
    getStatePageData();
    window.scrollTo(0, 0);
  }, []);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "agent_zip_code") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        zip_code: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsOpen((isOpen) => !isOpen);
    setisSubmit(true);
    addUserLead();
  };

  const handleSubmitzPro = (e) => {
    e.preventDefault();
    if (formData.address === "") {
      NotificationManager.error("Error", "Please fill all field", 3000);
      return false;
    }
    updateField("address", formData.address);
    updateField("agent_zip_code", formData.agent_zip_code);
    setIsOpen((isOpen) => !isOpen);
  };

  const updateField = (fieldName, newValue) => {
    setCookie((prevState) => {
      const newState = { ...prevState };
      newState[fieldName] = newValue;
      localStorage.setItem("formData", JSON.stringify(newState));
      return newState;
    });
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const zipCodeComponent = results[0].address_components.find((component) =>
        component.types.includes("postal_code")
      );

      const newZipCode = zipCodeComponent ? zipCodeComponent.short_name : "";
      const stateComponent = results[0].address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );

      const stateCode = stateComponent ? stateComponent.short_name : "";
      setAddress(selectedAddress);
      formData.address = selectedAddress;
      setZipCode(newZipCode);
      formData.agent_zip_code = newZipCode;
      formData.zip_code = newZipCode;
      formData.state = stateCode;
      localStorage.setItem("state", stateCode);
    } catch (error) {
      console.error("Error fetching location information:", error);
    }
  };

  const addUserLead = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/addLead`,
        formData,
        {
          headers: {
            Authorization: `Bearer 455454545ddsdksdjksdjksdsjdksjdksj`,
          },
          withCredentials: true,
        }
      );
      if (response.status) {
        localStorage.setItem("formData", JSON.stringify(formData));
        navigate("/agentslist");
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handlePriceChange = (event) => {
    setRange(event.target.value);
    setOnePer((event.target.value * 1) / 100);
    setTwoPer((event.target.value * 2) / 100);
    setThreePer((event.target.value * 3) / 100);
  };

  function unslug(text) {
    const stringWithSpaces = text.replace(/-/g, " ");
    const stringWithTitleCase = stringWithSpaces
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return stringWithTitleCase;
  }

  const getContentSplit = (content, charLimit) => {
    if (content.length <= charLimit) {
      return { previewContent: content, remainingContent: "" };
    }

    let sliceEnd = content.lastIndexOf(".", charLimit);
    let safeSliceEnd =
      sliceEnd > charLimit / 2 ? sliceEnd : content.indexOf(".", charLimit);
    if (safeSliceEnd === -1) safeSliceEnd = content.length;

    const previewContent = content.slice(0, safeSliceEnd + 1);
    const remainingContent = content.slice(safeSliceEnd + 1);

    return { previewContent, remainingContent };
  };
  if (loading || !districtInfo || !cityList) {
    return <Loader />;
  }
  return (
    <Layout>
      <Helmet>
        <title>{`Local 1% Listing Agents In ${districtInfo.name}, ${unslug(
          state
        )} | Listelligent`}</title>
        {/* <meta name="description" content={description} /> */}
      </Helmet>
      {loading && <Loader />}
      <NotificationContainer />
      <div
        className="form-container"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <Container>
          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <div className="form">
                <h3>One Step Away From Huge Savings!</h3>

                <Form onSubmit={handleSubmit}>
                  <Form.Control
                    className="mt-5 mb-4"
                    name="name"
                    size="lg"
                    type="text"
                    placeholder="First and Last Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control
                    className="mt-4 mb-4"
                    name="phone"
                    size="lg"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control
                    className="mt-1 mb-4"
                    name="email"
                    size="lg"
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control
                    className="mt-1 mb-4"
                    name="address"
                    size="lg"
                    type="text"
                    required
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  <Form.Control
                    className="mt-1 mb-4 zip_number"
                    name="zip_code"
                    size="lg"
                    type="number"
                    placeholder="Zip code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    required
                  />
                  <Button type="submit" variant="warning">
                    Continue
                  </Button>{" "}
                </Form>
                <div className="mt-4 text-center">
                  <p>
                    By continuing, you agree to receive emails and texts from
                    listelligent.com and other parties regarding your inquiry
                    and other home-related matters, including marketing via
                    automated voice solutions, prerecorded and artificial voice,
                    and text messages. This consent is provided independently of
                    any purchase you make, and it is applicable whether you
                    check or leave unchecked any of the above
                    boxes.Additionally, you accept our{" "}
                    <Link
                      to="/term-condition"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </Link>{" "}
                    ,{" "}
                    <Link
                      to="/cookie-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cookie policy
                    </Link>{" "}
                    and our{" "}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Link>{" "}
                    with reference to the data we collect about you. There may
                    be message and data fees. Even if your name is on a
                    corporate, state, or federal do-not-call list, this consent
                    is still valid.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              {" "}
              <CloseButton onClick={toggle} aria-label="Close" />
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="first-section"
        style={{ display: isOpen || isSubmit ? "none" : "block" }}
      >
        <NotificationContainer />
        <Container>
          <Row className="mt-3 first-inner-section mt-5">
            <Col>
              <Card className="mb-4 border-0">
                <Card.Body>
                  <Card.Title className="third-sec-heading">
                    <h1 className="orangeColorText">
                      Local 1% Listing Agents In {districtInfo.name},{" "}
                      {unslug(state)}
                    </h1>
                  </Card.Title>
                  <Row style={{ textAlign: "justify" }}>
                    <Col md={8}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitContent.previewContent,
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      {districtInfo.img ? (
                        <img
                          alt="state-img"
                          className="img-fluid"
                          src={`${process.env.REACT_APP_BASE_URL}assets/state/${districtInfo.img}`}
                        />
                      ) : (
                        <img
                          alt="state-img"
                          className="img-fluid"
                          src={require("../assets/no-image.png")}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: splitContent.remainingContent,
                        }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="border-0">
                <Card.Body>
                  <Card.Title className="third-sec-heading communitiesHeading ">
                    <h2 className="orangeColorText">
                      Areas In {districtInfo.name}
                    </h2>
                  </Card.Title>
                  <Row xs={1} md={4} className="g-4 mt-3 txtBlack">
                    {cityList.map((data, idx) => (
                      <Col key={idx} className="m-0 text-center p-1">
                        <Link to={data.slug}>
                          <b>{data.name}</b>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 first-inner-section">
            <div className="communitiesHeading m-4 extraCss">
              <h2 className="orangeColorText">
                1% Listing Agents In {districtInfo.name}, {unslug(state)}
              </h2>
            </div>
            <Col md={6} className="first-left-col p-5">
              <h4 className="mb-3">
                Sell Your Home for <span className="highLightText">1%</span>
                <sup style={{ top: "-1.5em" }}>
                  <Tooltip
                    title="*Buyer’s agent fee not included. Consult with your agent on local broker co-op commissions."
                    arrow
                  >
                    <span>
                      <BiInfoCircle />
                    </span>
                  </Tooltip>
                </sup>
              </h4>
              <h4 className="mb-4">Connect With Agents in Your Zip Code</h4>
            </Col>
            <Col md={6} className="home-img p-5">
              <div className="mb-4">
                <Form onSubmit={handleSubmitzPro}>
                  <h5 className="mb-4 text-center">
                    Enter your address to match with a local professional
                  </h5>
                  <InputGroup className="mt-3 zip-search-input-content home-search-bar">
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="position-relative homeAddress">
                          <input
                            {...getInputProps({
                              placeholder: "Type address",
                              className:
                                "form-control custom-input-radius custom-input-height shadow-none",
                            })}
                          />

                          {suggestions.length > 0 && (
                            <div
                              className="position-absolute start-0 mt-2"
                              style={{
                                background: "#fff",
                                border: "1px solid #ced4da",
                                borderRadius: "0.25rem",
                                zIndex: 1000,
                                width: "100%",
                              }}
                            >
                              <ul className="list-group">
                                {loading ? (
                                  <li className="list-group-item">
                                    Loading...
                                  </li>
                                ) : (
                                  suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                    };

                                    return (
                                      <li
                                        className="list-group-item"
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </li>
                                    );
                                  })
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <BsGeoAltFill />
                    <Form.Control
                      type="text"
                      placeholder="Zip Code"
                      className="shadow-none"
                      name="agent_zip_code"
                      value={formData.agent_zip_code}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                  <div className="text-center mt-2">
                    <Button
                      type="submit"
                      className="find-btn custom-button-css custom-button-orange-bg mt-3"
                    >
                      Find A Pro
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="second-section">
          <Container>
            <Row>
              <Col md={12} className="communitiesHeading ">
                <h2 className="textColor">
                  How much can I save by using a Listelligent pro?
                </h2>
                <h2 className="mt-4 textColor">Sales Price</h2>
              </Col>
            </Row>

            <Row>
              <Col md={3}></Col>
              <Col md={6} className="rang-label">
                <Form.Label>Listing Price</Form.Label>
                <center>
                  <h4>{formatCurrency(defaultRange)}</h4>
                </center>
                <Form.Range
                  min={300000}
                  max={3000000}
                  step={10}
                  value={defaultRange}
                  onChange={handlePriceChange}
                />
                <div className="d-flex justify-content-between">
                  <h5>{formatCurrency(300000)}</h5>
                  <h5>{formatCurrency(3000000)}</h5>
                </div>
              </Col>
              <Col md={3}></Col>
            </Row>

            <Row className="calculation-colunms justify-content-center">
              <Col md={6} sm={12} className="calculator-stat-listelligent">
                <p>Agent fees with listelligent</p>
                <p>1%</p>
                <h3>{formatCurrency(onePer)}</h3>
              </Col>
              <Col md={3} sm={12} className="calculator-stat-others">
                <p>Other Agents</p>
                <p>3%</p>
                <h3>{formatCurrency(threePer)}</h3>
              </Col>
              <Col md={3} sm={12} className="calculator-stat-saving">
                <p>Potential Savings:</p>
                <h3>{formatCurrency(twoPer)}</h3>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-5">
          <Container>
            <div className="communitiesHeading">
              <h2 className="orangeColorText">Your Local 1% Listing Agent</h2>
            </div>
            <Row>
              {agentList.map((result, index) => (
                <Col key={index} md={6}>
                  <div className="agent-profile">
                    <Row className="d-flex align-items-center">
                      <Col md={3} className="agent-img">
                        {result.agentData && result.agentData.profile !== "" ? (
                          <img
                            alt="agent"
                            src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${result.agentData.profile}`}
                          ></img>
                        ) : (
                          <img alt="agent" src={dummyAgent} />
                        )}
                      </Col>
                      <Col md={9}>
                        <div className="agentlist-btn mb-3 m-1">
                          <Link
                            to={`/agentprofile/${result.id}`}
                            style={{ fontSize: "20px" }}
                          >
                            {result.name}
                          </Link>
                        </div>
                        <p className="m-3 h4">
                          <span>{result.brokerage}</span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
              {agentList.length === 0 && <p>No agents found.</p>}
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default DistrictPage;
