import React, { useEffect, useState } from "react";
import Layout from "../../components/Layouts/Layout";
import "../../Style/Agents/Agentsignup.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import { AiFillPlayCircle } from "react-icons/ai";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "react-bootstrap/Accordion";
import agentsignup_page_img from "../../assets/agentsignup_page_img.jpg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { HiMiniCursorArrowRipple } from "react-icons/hi2";
import { CgNotes } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { RiUserSettingsLine } from "react-icons/ri";

const steps = [
  {
    label: "Claim your exclusive zip codes",
  },
  {
    label: "Setup a budget for Google Pay Per Click Campaigns",
  },
  {
    label: "Work the leads while Listelligent is runs your ad campaign",
  },
  // {
  //     label: 'Claim zip codes',
  // }
];

const Agentsignup = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [faqsList, setFaqsList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/agent-faqs`,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        setFaqsList(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <Layout>
      <div className="agent-header">
        <Container>
          <Row>
            <Col md={6} className="mb-1">
              <h1>Sell More Homes</h1>
              <p>No Referral Fees</p>
              <p>No Monthly Contracts</p>
              <p>Exclusive Zip Codes</p>
              <div className="text-center text-lg-start" style={{marginTop:"40px"}}>
                <Link
                  to={"/agentsignupform"}
                  className="btn btn-light text-dark newBtn"
                >
                  Join Listelligent&nbsp;
                  <FaArrowRight />
                </Link>
              </div>
              <div className="text-center text-lg-start">
                <Link
                  to={"/login"}
                  className="btn btn-light text-dark mt-1 newBtn"
                >
                  Login&nbsp;
                  <FaArrowRight />
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <img
                alt="agent"
                src={agentsignup_page_img}
                className="img-fluid"
                width={550}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="agentsign-first-section">
        <Container>
          <Row>
            <Col md={12}>
              <h1>
                Sign up with Listelligent and help homeowners list their home!
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="reason-item">
                <Row>
                  <Col md={1}>
                    <HiMiniCursorArrowRipple />
                  </Col>
                  <Col md={11}>
                    <h4>Automated Google Ads</h4>
                    <p>
                      Listelligent automates your google ads so you don’t have
                      to! Your monthly subscription will get you monthly
                      sponsored ads on the front page of google driving you more
                      leads each month.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="reason-item">
                <Row>
                  <Col md={1}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="2 2 24 24"
                      width="48px"
                      height="48px"
                    >
                      <rect
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="3"
                        ry="1"
                        fill="none"
                        stroke="#FF6C2C"
                        strokeWidth="2"
                      />
                      <text
                        x="12"
                        y="16"
                        fontFamily="Arial"
                        fontSize="12"
                        fill="#FF6C2C"
                        textAnchor="middle"
                        fontWeight="bold"
                      >
                        1
                      </text>
                    </svg>
                  </Col>
                  <Col md={11}>
                    <h4>Exclusive Zip Codes</h4>
                    <p>
                      Listelligent automates your google ads so you don’t have
                      to! Your monthly subscription will get you monthly
                      sponsored ads on the front page of google driving you more
                      leads each month.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="reason-item">
                <Row>
                  <Col md={1}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="48px"
                      height="48px"
                      fill="#FF6C2C"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="18"
                        height="18"
                        rx="3"
                        ry="3"
                        fill="none"
                        stroke="#FF6C2C"
                        strokeWidth="2"
                      />
                      <text
                        x="7"
                        y="13"
                        fontFamily="Arial"
                        fontSize="12"
                        fill="#FF6C2C"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontWeight="bold"
                      >
                        $
                      </text>
                      <rect x="12" y="7" width="4" height="2" fill="#FF6C2C" />
                      <rect x="12" y="15" width="4" height="2" fill="#FF6C2C" />
                    </svg>
                  </Col>
                  <Col md={11}>
                    <h4>Budget Control</h4>
                    <p>
                      Your monthly subscription will cover your monthly ad spend
                      and varies zip code to zip code. Within your profile you
                      have the option to manage your ad spend for more leads
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="reason-item">
                <Row>
                  <Col md={1}>
                    <CgNotes />
                  </Col>
                  <Col md={11}>
                    <h4>No Contracts</h4>
                    <p>
                      Listelligent automates your google ads so you don’t have
                      to! Your monthly subscription will get you monthly
                      sponsored ads on the front page of google driving you more
                      leads each month.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="reason-item">
                <Row>
                  <Col md={1}>
                    <div
                      style={{
                        border: "4px solid #FF6C2C",
                        display: "inline-flex",
                        padding: "2px",
                      }}
                    >
                      <span
                        style={{
                          color: "#FF6C2C",
                          fontWeight: "900",
                          marginTop: "-6px",
                        }}
                      >
                        5
                      </span>
                      <FaUser size={20} />
                    </div>
                  </Col>
                  <Col md={11}>
                    <h4>No referral fees…ever</h4>
                    <p>
                      Any lead you get from Listelligent is yours for life. If
                      they sell, buy and sell again you keep 100% of the
                      commissions!
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="reason-item" style={{ marginTop: "48px" }}>
                <Row>
                  <Col md={1}>
                    <RiUserSettingsLine />
                  </Col>
                  <Col md={11}>
                    <h4>No Ads Experience Needed</h4>
                    <p>
                      Listelligent does all the heavy lifting for you. You don’t
                      need any experience creating ads nor do you need a google
                      account. Simply claim a zip code and we will handle the
                      rest.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="agentsign-second-section">
        <Container>
          <Row>
            <Col md={6}>
              <h1>Generate Leads From Your Exclusive Zip Codes</h1>
              <Box sx={{ maxWidth: 600 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label} className="agent-step-label">
                      <StepLabel
                        className="steplabels"
                        // optional={
                        //   index === 2 ? (
                        //     <Typography variant="caption">Last step</Typography>
                        //   ) : null
                        // }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                        {/* <Box sx={{ mb: 2 }}> */}
                        {/* <div>
                            <Button
                              variant="contained"
                              sx={{ mt: 1, mr: 1 }}
                              className="signup-btn"
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Sign up Now"}                               
                              {index === steps.length - 1 ? (
                                <>Finish</>
                              ) : (
                                // <Link to="/agentsignupform" className="text-white">Sign up Now</Link>
                              )} &nbsp;
                              <FaArrowRight />
                            </Button>
                          </div> */}
                        {/* </Box> */}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Col>
            <Col md={6}>
              <div className="video-popup">
                <img
                  src={require("../../../src/assets/agent-video-thumb.png")}
                  alt="Listelligent Video"
                  className="img-fluid"
                ></img>
                <Link onClick={() => setModalShow(true)}>
                  <AiFillPlayCircle />
                </Link>
                <Modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <video
                      alt="Listelligent Video"
                      src={require("../../../src/assets/for-agent.mp4")}
                      width="100%"
                      height="auto"
                      controls
                      autoPlay
                    ></video>
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="agentsign-third-section">
        <Container>
          <Row>
            <Col>
              <h1>Frequently Asked Questions</h1>
              <Accordion defaultActiveKey="0" className="accordian-container">
                {faqsList.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header className="acordian-btn">
                      {item.question}
                    </Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="agentsign-fourth-section">
        <Container>
          <Row className="d-flex align-items-center">
            <Col md={7}>
              <h1>Ready to take on more listings?</h1>
            </Col>
            <Col md={5}>
              <div className="text-center text-lg-start">
                <Link
                  to={"/agentsignupform"}
                  className="btn btn-light text-dark"
                >
                  Join Listelligent
                  <FaArrowRight />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Agentsignup;
